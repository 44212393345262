import * as THREE from 'three';

import {
  EffectComposer,
} from 'three/examples/jsm/postprocessing/EffectComposer.js';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js';
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass.js';

// import 'regenerator-runtime/runtime';

import each from 'lodash/each';

import fragment from './shader/composer/fragment.glsl';
import vertex from './shader/composer/vertex.glsl';

import gsap from 'gsap';

import Preloader from './Component/Preloader';
import Loader from './Component/Loader';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Project from './pages/project/Project';
import Nf from './pages/404/Nf';
import {hide, hideBc} from './utils/fn';
import {ft, findR, findPrR} from './utils/ft';

const d = '/d';

export default class App {
  constructor (options) {
    this.scene = new THREE.Scene ();

    this.container = options.dom;
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer = new THREE.WebGLRenderer ({alpha: true, antialias: true});
    this.renderer.setPixelRatio (Math.min (window.devicePixelRatio, 2));
    this.renderer.setSize (this.width, this.height);
    this.manager = new THREE.LoadingManager ();
    this.loader = new THREE.TextureLoader (this.manager);
    this.preloaded = false;
    this.i = 0;
    this.iNav = document.querySelector ('.nav-index');

    this.container.appendChild (this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera (
      70,
      window.innerWidth / window.innerHeight,
      100,
      2000
    );
    this.content = document.querySelector ('main');
    this.preloader = document.querySelector ('.preloader');

    this.camera.position.set (0, 0, 600);
    this.time = 0;

    this.ld ();
  }

  async ld () {
    const ua = navigator.userAgent;
    const isMb =
      /Mobi|Android|iPad|iPhone/.test (ua) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    if (isMb) {
      // const d = 'd.json';
      // this.d = await ft (d);

      // this.content.innerHTML = this.d.pages['/'];

      document.documentElement.classList.add ('mobile');

      const home = document.querySelector ('.h-m');

      // setTimeout (() => {
      //   home.style.display = 'block';
      // }, 2555);

      gsap.to ('.p-wp', {
        y: '-100%',
        duration: 1.2,
        ease: 'Power3.easeInOut',
        delay: 3,
      });
    } else {
      this.createPreloader ();
      this.createGeometry ();
      this.createLoader ();
      this.createPage ();
      this.composerPass ();
      this.render ();
      this.addEvents ();
      this.onResize ();
    }
  }

  createPreloader () {
    this.preloader = new Preloader ({
      element: this.preloader,
    });
  }

  createGeometry () {
    this.geometry = new THREE.PlaneBufferGeometry (1, 1, 30, 30);
  }

  createLoader () {
    this.pageLoad = new Loader ({
      geometry: this.geometry,
      scene: this.scene,
      manager: this.manager,
      loader: this.loader,
    });
  }

  createHome () {
    this.home = new Home ({
      scene: this.scene,
      loader: this.loader,
      geometry: this.geometry,
      pass: this.ctP,
      curtain: this.pageLoad,
      i: this.i,
    });
    this.current = this.home;
    this.current.on = true;
  }

  destroyHome () {
    if (!this.home) return;
    this.home.destroy ();
    this.home = null;
  }

  createAbout () {
    this.about = new About ({
      scene: this.scene,
      loader: this.loader,
      geometry: this.geometry,
      pass: this.ctP,
    });
    this.current = this.about;
    this.current.on = true;
    this.current.out = true;
  }

  destroyAbout () {
    if (!this.about) return;
    this.about.destroy ();
    this.about = null;
  }

  createProject (ap = false, c) {
    this.project = new Project ({
      scene: this.scene,
      loader: this.loader,
      geometry: this.geometry,
      pass: this.ctP,
      camera: this.camera,
      ap,
      c,
    });
    this.current = this.project;
    this.current.on = true;
  }

  destroyProject () {
    if (!this.project) return;
    this.project.destroy ();
    this.project = null;
  }

  create404 () {
    this.nf = new Nf ();
    this.current = this.nf;
  }

  destroy404 () {
    if (!this.nf) return;
    this.nf = null;
  }

  async createPage () {
    // const yoyo = await fetch ('views/home.html');
    // const yu = await yoyo.text ();
    // console.log (await JSON.stringify (yu));

    this.d = await ft (d);
    const url = location.origin + location.pathname;
    const urlSplit = url.replace (window.location.origin, '');
    let foundRoute = '';
    let foundPr = '';

    if (url.indexOf ('/project/') > -1) {
      foundPr = findPrR (this.d, urlSplit);
      if (!foundPr) {
        foundRoute = findR (this.d, '/404');
      }
    } else {
      foundRoute = findR (this.d, urlSplit);
      if (!foundRoute) {
        foundRoute = findR (this.d, '/404');
      }
    }

    if (foundRoute) {
      this.ch = this.d.pages[foundRoute];
    } else if (foundPr) {
      this.ch = this.d.pages.projects[foundPr].h;
      this.c = this.d.pages.projects[foundPr].c;
      this.i = this.d.pages.projects[foundPr].i;
      foundRoute = 'project';
    }
    this.content.innerHTML = this.ch;

    await this.pageLoad.onLoad (this.d);

    foundRoute === '/about' || foundRoute === 'project'
      ? (this.iNav.style.transform = 'translateY(0)')
      : (this.iNav.style.transform = 'translateY(120%)'), (this.in = false);

    if (foundRoute === '/404') {
      this.iNav.style.transform = 'translateY(0)';
    }

    setTimeout (() => {
      switch (foundRoute) {
        case '/':
          this.createHome ();
          break;
        case '/about':
          this.createAbout ();
          break;
        case 'project':
          this.createProject (false, this.c);
          break;
        default:
          this.create404 ();
          break;
      }
    }, 10);

    this.addLinkListeners ();

    setTimeout (() => {
      this.preloader.animateOut ();
      this.pageLoad.animateIn ();
      this.current.intro (true);
    }, 2000);

    // setTimeout (() => {
    //   this.preloader.animateOut ();
    //   this.current.intro ();
    // }, 5000);
  }

  /**
   * EVENTS
   */

  async onChange({url, push = true}) {
    const urlSplit = url.replace (window.location.origin, '');

    let foundRoute = '';
    let foundPr = '';

    if (this.current.type === 'Project') {
      this.ap = true;
    } else {
      this.ap = false;
    }

    if (url.indexOf ('/project/') > -1) {
      foundPr = findPrR (this.d, urlSplit);
      if (!foundPr) {
        foundRoute = findR (this.d, '/404');
      }
    } else {
      foundRoute = findR (this.d, urlSplit);
      if (!foundRoute) {
        foundRoute = findR (this.d, '/404');
      }
    }

    if (foundRoute) {
      this.ch = this.d.pages[foundRoute];
    } else if (foundPr) {
      this.ch = this.d.pages.projects[foundPr].h;
      this.c = this.d.pages.projects[foundPr].c;
      this.i = this.d.pages.projects[foundPr].i;
      foundRoute = 'project';
    }

    if (!push) {
      const divs = this.content.querySelectorAll ('.page');
      if (divs.length >= 2) {
        divs.forEach (el => el.parentElement.removeChild (el));
        document.body.style.pointerEvents = 'all';
      } else {
        const div = this.content.querySelector ('.page');
        div.parentElement.removeChild (div);
        document.body.style.pointerEvents = 'all';
      }

      this.content.insertAdjacentHTML ('beforeend', this.ch);
      foundRoute === '/about' || foundRoute === 'project'
        ? (this.iNav.style.transform = 'translateY(0)')
        : (this.iNav.style.transform = 'translateY(120%)');

      if (foundRoute === '/404') {
        this.iNav.style.transform = 'translateY(0)';
      }

      switch (foundRoute) {
        case '/':
          this.about = null;
          this.project = null;
          this.createHome ();
          break;
        case '/about':
          this.project = null;
          this.home = null;
          this.createAbout ();
          break;
        case 'project':
          this.about = null;
          this.home = null;
          this.createProject (false, this.c);
          break;
        default:
          this.create404 ();
          break;
      }
      this.current.introH ();

      this.addLinkListeners ();

      return;
    }

    this.content.insertAdjacentHTML ('beforeend', this.ch);
    const divs = this.content.querySelectorAll ('.page');
    this.ap ? divs[1].classList.add ('p2') : '';
    this.ap ? divs[0].classList.remove ('p2') : '';

    if (foundRoute === '/') {
      gsap.to ('.nav-index', {
        y: '-105%',
        duration: 1.1,
        ease: 'Power3.easeInOut',
        onComplete: () => (this.in = false),
      });
    }
    this.current.hideBc ();

    this.prev = this.current;
    this.prev.on = false;

    switch (foundRoute) {
      case '/':
        this.createHome ();
        break;
      case '/about':
        this.createAbout ();
        break;
      case 'project':
        this.createProject (this.ap, this.c);
        break;
      default:
        break;
    }
    await hideBc (divs[0]);

    this.prev.destroy ();

    if (
      (!this.in && foundRoute === 'project') ||
      (!this.in && foundRoute === '/about')
    ) {
      gsap.fromTo (
        '.nav-index',
        {y: '105%'},
        {
          y: 0,
          duration: 1.1,
          ease: 'Power3.easeOut',
          onComplete: () => (this.in = true),
        }
      );
    }

    if (push) {
      this.rm (divs[0]);
    }

    gsap.fromTo (
      divs[1],
      {autoAlpha: 0},
      {
        autoAlpha: 1,
        duration: 0.25,
        delay: 0.1,
        ease: 'Power3.easeIn',
        onComplete: () => {
          // this.rm (divs[0]);
        },
      }
    );
    this.current.intro ();

    if (push) {
      window.history.pushState ({}, '', url);
    }

    this.addLinkListeners ();
  }

  rm (d) {
    this.cl1 = setTimeout (() => {
      d.classList.remove ('page');
      document.body.style.pointerEvents = 'all';
      d.parentElement.removeChild (d);
    }, 1200);
    this.cl2 = setTimeout (() => {}, 1650);
  }

  async onPopState () {
    clearTimeout (this.cl1);
    clearTimeout (this.cl2);
    document.body.style.pointerEvents = 'none';
    this.ctP.material.uniforms.speed.value = 0;
    this.current.hDestroy ();

    this.onChange ({
      url: window.location.pathname,
      push: false,
    });
  }

  onResize () {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.camera.fov = 2 * Math.atan (this.height / 2 / 600) * (180 / Math.PI);
    this.renderer.setSize (this.width, this.height);
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix ();

    this.home && this.home.onResize ();

    this.about && this.about.onResize ();

    this.pageLoad && this.pageLoad.onResize ();

    this.project && this.project.on && this.project.onResize ();
  }

  onWheel (e) {
    this.home && this.home.onWheel (e);

    this.about && this.about.onWheel (e);

    this.project && this.project.onWheel (e);
  }

  composerPass () {
    this.composer = new EffectComposer (this.renderer);
    this.renderPass = new RenderPass (this.scene, this.camera);
    this.composer.addPass (this.renderPass);

    this.myEffect = {
      uniforms: {
        speed: {value: null},
        tDiffuse: {value: null},
        time: {value: null},
      },
      vertexShader: vertex,
      fragmentShader: fragment,
    };

    this.ctP = new ShaderPass (this.myEffect);
    this.ctP.renderToScreen = true;

    this.composer.addPass (this.ctP);
  }

  /**
   * LOOP
   */

  render () {
    this.time += 0.05;

    this.home && this.home.on && this.home.update (this.time);

    this.about && this.about.on && this.about.update (this.time);

    this.project && this.project.on && this.project.update (this.time);

    requestAnimationFrame (this.render.bind (this));
    this.composer.render ();
  }

  /**
   * EVENT LISTENERS
   */

  addLinkListeners () {
    const links = document.querySelectorAll ('a');

    each (links, link => {
      const isLocal = link.href.indexOf (window.location.origin) > -1;

      if (isLocal) {
        link.onclick = async e => {
          e.preventDefault ();

          let url = link.href;

          if (url === window.location.origin + window.location.pathname) return;

          document.body.style.pointerEvents = 'none';
          this.onChange ({url});
        };
      } else {
        link.rel = 'noopener';
        link.target = '_blank';
      }
    });
  }

  addEvents () {
    window.addEventListener ('resize', this.onResize.bind (this));
    window.addEventListener ('wheel', this.onWheel.bind (this));
    window.addEventListener ('popstate', this.onPopState.bind (this));
  }
}

new App ({
  dom: document.getElementById ('gl'),
});
