export default class Animation {
  constructor({el}) {
    this.el = el;

    this.createObserver ();
  }

  createObserver () {
    this.oberserver = new IntersectionObserver (entries => {
      entries.forEach (entry => {
        if (entry.isIntersecting) {
          this.animateIn ();
        } else {
          this.animateOut ();
        }
      });
    });
    this.oberserver.observe (this.el);
  }

  animateIn () {}

  animateOut () {}

  onResize () {}
}
