import * as THREE from 'three';

import gsap from 'gsap';

import fragment from '../../shader/project/fragment';
import vertex from '../../shader/project/vertex';

import {gb} from '../../utils/gb';

export default class Media {
  constructor({el, scene, geometry, index}) {
    this.el = el;
    this.scene = scene;
    this.geometry = geometry;
    this.index = index;
    this.side = 1;
    this.e = {
      i: 1,
    };
    this.createMeash ();
  }

  intro (f) {
    let d = f ? 0.5 : 0.0;

    gsap.to (this.e, {
      i: 0,
      delay: d + 0.1 * this.index,
      duration: f ? 1.95 : 1.65,
      ease: 'Expo.easeOut',
    });
  }

  hideBc () {
    this.stop = true;
    return;
    gsap.to (this.mesh.position, {
      y: this.mesh.position.y + window.innerHeight * 0.2,
      duration: 1.1,
      ease: 'Power3.easeInOut',
    });
    gsap.to (this.mesh.scale, {
      y: this.b.height * 0.95,
      x: this.b.width * 0.95,
      duration: 1.1,
      ease: 'Power3.easeInOut',
    });
    gsap.to (this.material.uniforms.a, {
      value: 0,
      duration: 1.45,
      ease: 'Power3.easeInOut',
    });
  }

  createMeash () {
    const d = this.el.getAttribute ('data-src');

    const obj = window.T[d];
    this.el.appendChild (obj[3]);

    this.el = obj[3];
    const t = obj[0];
    return;
    t.minFilter = THREE.LinearFilter;
    t.magFilter = THREE.LinearFilter;

    this.material = new THREE.ShaderMaterial ({
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: {
        tex: {value: t},
        time: {value: 0},
        a: {value: 0},
        ps: {value: new THREE.Vector2 ()},
        ns: {value: new THREE.Vector2 (obj[1], obj[2])},
      },
      transparent: true,
      visible: false,
    });
    this.mesh = new THREE.Mesh (this.geometry, this.material);
    this.scene.add (this.mesh);
  }

  cb () {
    return;
    this.b = gb (this.el);

    const {width, height} = this.b;
    this.material.uniforms.ps.value.x = this.b.width;
    this.material.uniforms.ps.value.y = this.b.height;

    this.us (width, height);
  }

  us (w, h) {
    this.mesh.scale.set (w, h, 1);

    this.ux ();
    this.uy (0, window.innerHeight);
  }

  rs () {
    this.cb ();
  }

  ux () {
    this.mesh.position.x =
      this.b.left - window.innerWidth / 2 + this.b.width / 2;
  }

  uy (y = 0, i) {
    this.mesh.position.y =
      y - i - this.b.top + window.innerHeight / 2 - this.b.height / 2;
  }

  i (x) {
    return x < 0.5 ? 2 * x * x : -1 + (4 - 2 * x) * x;
  }

  update (s) {
    if (this.stop) return;

    let i = window.innerHeight * this.i (this.e.i) * this.side;
    this.el.style.transform = `translate3d(0,${i - s}px,0)`;
    return;
    this.material.uniforms.a.value = 1 - this.i (this.e.i);
    this.uy (s, i);
  }
}
