import gsap from 'gsap';

export default class Nf {
  constructor () {}

  intro () {
    gsap.to ('span', {
      y: 0,
      duration: 1.5,
      stagger: 0.13,
      delay: 0.8,
      ease: 'Expo.easeOut',
    });
  }

  introH () {
    gsap.to ('span', {
      y: 0,
      duration: 1.5,
      stagger: 0.13,
      ease: 'Expo.easeOut',
    });
  }

  hideBc () {}

  rs () {}

  destroy () {}

  hDestroy () {}
}
