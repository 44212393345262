import * as THREE from 'three';
import gsap from 'gsap';

import Media from './Media';
import Video from './Video';
import {gb} from '../../utils/gb';
import fragment from '../../shader/project/fx/fragment.glsl';
import vertex from '../../shader/project/fx/vertex.glsl';

import map from 'lodash/map';

export const clamp = (num, min, max) =>
  num <= min ? min : num >= max ? max : num;

export const mapR = (x, a, b, c, d) =>
  clamp ((x - a) * (d - c) / (b - a) + c, Math.min (c, d), Math.max (c, d));

export default class Project {
  constructor({scene, loader, geometry, pass, camera, ap, c}) {
    this.scene = scene;
    this.loader = loader;
    this.geometry = geometry;
    this.pass = pass;
    this.camera = camera;
    this.ap = ap;
    this.tc = JSON.parse (c);

    this.on = false;
    this.play = false;
    this.scroll = {
      current: 0,
      target: 0,
      ease: 0.1,
      limit: 0,
    };
    this.e = {
      i: 1,
    };
    this.s = 0;
    this.type = 'Project';

    if (this.ap) {
      this.videos = [...document.querySelectorAll ('.p2 video')];
      this.images = [...document.querySelectorAll ('.p2 .project-gl')];

      this.wrapper = document.querySelector ('.project.p2');

      if (this.images) {
        this.c = document.querySelector ('.p2 #project');
      }
      this.rf = document.querySelector ('.p2 .next-rf');
      this.next = document.querySelector ('.p2 .next-p');
      this.nText = document.querySelector ('.p2 .next-text');
      this.nTitle = document.querySelector ('.p2 .next-title');
      this.mk = document.querySelector ('.p2 .next-mk');
      this.lk = document.querySelector ('.p2 .next-lk');
    } else {
      this.videos = [...document.querySelectorAll ('video')];
      this.images = [...document.querySelectorAll ('.project-gl')];
      this.wrapper = document.querySelector ('.project');

      if (this.images) {
        this.c = document.querySelector ('#project');
      }
      this.rf = document.querySelector ('.next-rf');
      this.next = document.querySelector ('.next-p');
      this.nText = document.querySelector ('.next-text');
      this.nTitle = document.querySelector ('.next-title');
      this.mk = document.querySelector ('.next-mk');
      this.lk = document.querySelector ('.next-lk');
    }

    this.initCan ();
    this.destroyMeshes ();
    this.createFx ();
    this.createMedias ();
    this.onResize ();
    this.ael ();
  }

  initCan () {
    if (this.videos.length > 1) return;
    if (this.ap) {
      this.c = document.querySelector ('.p2 #project');
    } else {
      this.c = document.querySelector ('#project');
    }
    this.ctx = this.c.getContext ('2d');

    this.th = {
      start: -0.5,
      end: -0.5,
    };
    this.fc = 1;
  }

  introH () {
    this.intro (false);
  }

  intro (f) {
    setTimeout (() => {
      this.pass.material.uniforms.speed.value = 0;
    }, 500);

    let t = 0;
    let t2 = 0;
    f ? (t = 1) : (t = 0);
    f ? (t2 = 0.5) : (t2 = 0);
    let revs;
    let drc = '';
    gsap.to (this.e, {
      i: 0,
      delay: t2 + 0.1,
      duration: f ? 1.95 : 1.65,
      ease: 'Expo.easeOut',
    });
    if (this.ap) {
      revs = document.querySelectorAll ('.p2 .project-rev');
      drc = document.querySelectorAll ('.p2 .project_description');
    } else {
      revs = document.querySelectorAll ('.project-rev');
      drc = document.querySelectorAll ('.project_description');
    }
    this.to = setTimeout (() => {
      // document.body.style.pointerEvents = 'all';
      this.clickable = true;
      this.play = true;
    }, 1200);
    this.store.forEach (el => el.intro (f));
    gsap.to (revs, {
      y: 0,
      duration: 1.3,
      stagger: 0.12,
      ease: 'Expo.easeOut',
      delay: t,
    });

    gsap.fromTo (
      drc,
      {y: '120%'},
      {
        y: 0,
        duration: 1.3,
        ease: 'Expo.easeOut',
        delay: t,
      }
    );

    if (this.videos.length > 1) return;

    gsap.to (this.th, {
      end: 3.5,
      start: 1.5,
      duration: 1.2,
      ease: 'Power4.easeInOut',
      delay: t + 0.55,
    });

    gsap.to ('.project-ar', {
      opacity: 1,
      x: 0,
      delay: t + 0.7,
      duration: 0.7,
      ease: 'Power4.easeInOut',
    });
  }

  createFx () {
    this.group = new THREE.Group ();
    this.scene.add (this.group);
    let nImg = '';
    if (this.ap) {
      nImg = document.querySelector ('.p2 .next-rf');
    } else {
      nImg = document.querySelector ('.next-rf');
    }
    const d = nImg.getAttribute ('data-src');

    const obj = window.T[d];
    const t = obj[0];

    this.material = new THREE.ShaderMaterial ({
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: {
        tex: {value: t},
        ns: {value: new THREE.Vector2 (obj[1], obj[2])},
        ps: {value: new THREE.Vector2 ()},
        fc1: {value: 0},
        fc2: {value: 0},
        a: {value: 1},
        v: {value: 0},
        tc: {
          value: new THREE.Vector3 (
            this.tc[0] / 255,
            this.tc[1] / 255,
            this.tc[2] / 255
          ),
        },
      },
      transparent: true,
    });

    this.mesh = new THREE.Mesh (this.geometry, this.material);

    this.group.add (this.mesh);
  }

  destroyMeshes () {
    // const meshes = this.scene.children;
    // meshes.forEach (mesh => {
    //   if (mesh.name !== 'loader') {
    //     this.scene.remove (mesh);
    //   }
    // });
    // this.group = new THREE.Group ();
    // this.scene.add (this.group);
  }

  hideBc () {
    clearTimeout (this.to);
    this.stop = true;
    gsap.to (this.mesh.position, {
      y: this.mesh.position.y + window.innerHeight * 0.18,
      // x: this.mesh.position.x - window.innerHeight * 0.05,
      duration: 1.2,
      ease: 'Power3.easeInOut',
    });
    gsap.to (this.mesh.scale, {
      // y: 30 * this.rm * 0.85,
      // x: 30 * this.rm * 0.85,
      duration: 1.2,
      ease: 'Power3.easeInOut',
    });
    gsap.to (this.material.uniforms.a, {
      value: 0,
      duration: 1.2,
      ease: 'Power3.easeInOut',
    });
    this.store.forEach (el => el.hideBc ());
  }

  onResize () {
    this.v = {
      w: window.innerWidth,
      h: window.innerHeight,
    };
    this.scroll.limit = gb (this.wrapper).height - window.innerHeight;
    this.cb (this.v);
    if (this.videos.length > 1) return;

    this.store.forEach (el => el.rs ());
  }

  createMedias () {
    if (this.videos.length > 1) {
      this.store = map (this.videos, (el, index) => {
        return new Video ({
          el,
          index,
        });
      });
    } else {
      this.store = map (this.images, (el, index) => {
        return new Media ({
          el,
          scene: this.group,
          geometry: this.geometry,
          index,
        });
      });
    }
  }

  oE () {
    gsap.to (this.material.uniforms.v, {
      value: 1,
      duration: 0.3,
    });
  }

  oL () {
    if (this.cl) return;
    gsap.to (this.material.uniforms.v, {
      value: 0,
      duration: 0.3,
    });
  }

  cb (v) {
    const {w, h} = v;
    this.nxb = gb (this.next);
    const {height: nh, width: nw, left} = this.nxb;
    this.rm = w / 1920 * 10;
    this.tr = h - 27 * this.rm - nh - 5 * this.rm;
    this.material.uniforms.ps.value.x = nw;
    this.material.uniforms.ps.value.y = nh;

    this.mesh.scale.set (nw, nh, 1);
    this.mesh.position.x = left - w / 2 + nw / 2;

    if (this.videos.length > 1) return;

    this.b = gb (this.c);
    const {width, height} = this.b;

    this.size = {
      x: width,
      y: height,
      radius: height,
    };

    this.c.height = height * 2;
    this.c.width = width * 2;

    this.d ();
  }

  d () {
    if (this.videos.length > 1) return;
    const {x, y, radius} = this.size;
    const {start, end} = this.th;

    this.ctx.clearRect (0, 0, this.c.width, this.c.height);

    this.ctx.beginPath ();
    this.ctx.arc (
      x,
      y,
      this.fc * radius - 2 * this.fc,
      start * Math.PI,
      end * Math.PI
    );
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = '#000000';
    this.ctx.stroke ();
  }

  onWheel (e) {
    this.scroll.target += e.deltaY;
  }

  update (time) {
    if (this.stop) return;
    this.scroll.target = gsap.utils.clamp (
      0,
      this.scroll.limit,
      this.scroll.target
    );
    this.scroll.current = gsap.utils.interpolate (
      this.scroll.current,
      this.scroll.target,
      this.scroll.ease
    );
    const h = window.innerHeight;
    this.d ();
    const fc = clamp ((this.scroll.current + 0.01) / this.scroll.limit, 0, 1);
    const fc2 = mapR (fc, 0.1, 1, 0, 1);
    const o = mapR (fc, 0.9, 1, 0, 1);

    this.next.style.transform = `translateY(${this.tr * fc}px)`;
    this.nText.style.opacity = o;
    this.nTitle.style.opacity = o;

    this.store && this.store.forEach (el => el.update (this.scroll.current));

    if (this.clickable) {
      fc < 0.95
        ? (this.lk.style.pointerEvents = 'none')
        : (this.lk.style.pointerEvents = 'all');
    }

    this.s = this.scroll.current;
    this.material.uniforms.fc1.value = this.i2 (fc);
    this.material.uniforms.fc2.value = this.i2 (fc2);
    this.mesh.position.y =
      -window.innerHeight * this.i (this.e.i) +
      fc * -this.tr -
      22 * this.rm +
      h / 2 -
      15 * this.rm;
  }

  lkck () {
    this.cl = true;
    this.lk.style.pointerEvents = 'none';
  }

  i (x) {
    return x < 0.5 ? 2 * x * x : -1 + (4 - 2 * x) * x;
  }
  i2 (x) {
    return -(Math.cos (Math.PI * x) - 1) / 2;
  }

  ael () {
    this.lk.addEventListener ('click', this.lkck.bind (this));
    this.lk.addEventListener ('mouseenter', this.oE.bind (this));
    this.lk.addEventListener ('mouseleave', this.oL.bind (this));
  }

  rel () {
    this.lk.removeEventListener ('click', this.lkck.bind (this));
    this.lk.removeEventListener ('mouseenter', this.oE.bind (this));
    this.lk.removeEventListener ('mouseleave', this.oL.bind (this));
  }

  hDestroy () {
    this.rel ();
    this.scene.remove (this.group);
  }

  destroy () {
    this.rel ();
    setTimeout (() => {
      this.scene.remove (this.group);
    }, 1200);
  }
}
