import * as THREE from 'three';

import 'regenerator-runtime/runtime';

import gsap from 'gsap';

import fragment from '../../shader/home/fragment';
import vertex from '../../shader/home/vertex.glsl';
import {gb} from '../../utils/gb';

export default class Media {
  constructor({
    image,
    geometry,
    scene,
    loader,
    index,
    wrapper,
    displacement,
    text,
    i,
    length,
    curr,
  }) {
    this.image = image;
    this.geometry = geometry;
    this.scene = scene;
    this.loader = loader;
    this.index = index;
    this.extra = 0;
    this.wrapper = wrapper;
    this.displacement = displacement;
    this.text = text;
    this.i = i;
    this.length = length;
    this.tr = this.image.parentElement;
    this.curr = curr;
    this.v = this.image.parentElement.querySelector ('a');

    this.ct = 0;
    this.dt = 0;
    this.s = 0;

    this.createMesh ();
    this.ael ();
  }

  intro (f) {
    let t = 0;
    f ? (t = 0.15) : (t = 0);
    gsap.to (this.material.uniforms.of, {
      value: 0,
      duration: f ? 1.95 : 0.85,
      delay: f ? 0.15 : 0,
      ease: f ? 'Power3.easeOut' : 'Power3.easeOut',
    });
    gsap.to (this.material.uniforms.a, {
      value: 1,
      duration: 0.10,
      delay: 0,
    });
    gsap.to (this.material.uniforms.index, {
      value: 0,
      duration: f ? 1.95 : 0.85,
      delay: f ? 0.15 : 0,
      ease: f ? 'Power3.easeOut' : 'Power3.easeOut',
    });
  }

  createMesh () {
    const d = this.image.getAttribute ('data-src');

    const obj = window.T[d];
    const t = obj[0];
    t.minFilter = THREE.LinearFilter;
    t.magFilter = THREE.LinearFilter;
    this.image.src = obj[3].src;
    let id = 0;

    if (this.i === this.index) {
      id = 2;
    } else if (this.i + 1 === this.index) {
      id = -2;
    } else if (this.i === 0 && this.index === this.length) {
      id = 2;
    } else if (this.i === this.length && this.index === 0) {
      id = -2;
    } else if (this.i - 1 === this.index) {
      id = 2;
    } else {
      id = 0;
    }

    this.material = new THREE.ShaderMaterial ({
      visible: false,
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: {
        tex: {value: obj[0]},
        time: {value: 0},
        fc: {value: 1},
        p: {value: 0},
        a: {value: 0},
        disp: {value: this.displacement},
        ps: {value: new THREE.Vector2 ()},
        ns: {value: new THREE.Vector2 (obj[1], obj[2])},
        natural: {value: new THREE.Vector2 ()},
        index: {value: this.index === this.i ? 0 : 1},
        of: {value: id},
        v: {value: 0},
      },
      transparent: true,
    });

    this.mesh = new THREE.Mesh (this.geometry, this.material);

    this.scene.add (this.mesh);
  }

  hide () {
    this.top = true;

    gsap.to (this.material.uniforms.a, {
      value: 0,
      duration: 0.15,
    });
  }

  hideBc () {
    this.stop = true;
    gsap.to (this.mesh.position, {
      y: this.mesh.position.y + window.innerHeight * 0.2,
      duration: 1.2,
      ease: 'Power3.easeInOut',
    });
    gsap.to (this.mesh.scale, {
      y: this.b.height * 0.95,
      x: this.b.width * 0.95,
      duration: 1.2,
      ease: 'Power3.easeInOut',
    });
    gsap.to (this.material.uniforms.a, {
      value: 0,
      duration: 1.2,
      ease: 'Power3.easeInOut',
    });
  }

  oE () {
    gsap.to (this.material.uniforms.v, {
      value: 1,
      duration: 0.3,
    });
  }

  oE () {
    gsap.to (this.material.uniforms.v, {
      value: 1,
      duration: 0.3,
    });
  }

  oL () {
    if (this.clamp) return;
    gsap.to (this.material.uniforms.v, {
      value: 0,
      duration: 0.3,
    });
  }

  oC () {
    this.clamp = true;
  }

  cb () {
    this.b = this.image.getBoundingClientRect ();

    this.material.uniforms.ps.value.x = this.b.width;
    this.material.uniforms.ps.value.y = this.b.height;
    this.material.visible = true;

    const {top, bottom, height} = this.b;

    this.ct = 0;
    this.dt = 0;
    this.s = 0;

    this.top = height + top;
    this.bottom = bottom - height - window.innerHeight;
    this.tr.transform = 'translateY(0)';
    this.updateScale ();
  }

  onResize (height) {
    this.rs = true;
    this.tr.transform = 'translateY(0)';
    this.h = height;
    this.extra = 0;
    this.cb ();
    this.rs = false;
  }

  updateScale () {
    this.mesh.scale.set (this.b.width, this.b.height, 1);

    this.updateX ();
    this.updateY ();
  }

  updateX () {
    this.mesh.position.x =
      this.b.left - window.innerWidth / 2 + this.b.width / 2;
  }

  updateY (y = 0) {
    this.mesh.position.y =
      y - this.b.top + window.innerHeight / 2 - this.b.height / 2 + this.extra;
  }

  update (y, time, si) {
    if (this.stop) return;
    if (this.rs) return;
    if (!this.b) return;
    this.material.uniforms.time.value = time;

    this.updateY (this.s);
    this.tr.style.transform = `translateY(${-this.s}px)`;
    this.s = y - this.dt;

    if (
      (this.b.top - this.s < window.innerHeight / 2 + 2.5 &&
        si === 'u' &&
        this.b.bottom - this.s > window.innerHeight / 2 - 2.5) ||
      (this.b.bottom - this.s > window.innerHeight / 2 - 2.5 &&
        si === 'd' &&
        this.b.top - this.s < window.innerHeight / 2 + 2.5)
    ) {
      this.text.classList.add ('is-active');
      this.curr.innerHTML = this.index + 1;
    } else {
      this.text.classList.remove ('is-active');
    }

    if (si === 'u') {
      if (this.top - this.s < 0) {
        this.ct += 1;
        this.dt = this.h * this.ct;
      }
    }

    if (si === 'd') {
      if (this.bottom - this.s > 0) {
        this.ct -= 1;
        this.dt = this.h * this.ct;
      }
    }
  }

  ael () {
    this.v.addEventListener ('mouseenter', this.oE.bind (this));
    this.v.addEventListener ('mouseleave', this.oL.bind (this));
    this.v.addEventListener ('click', this.oC.bind (this));
  }

  destroy () {
    this.v.removeEventListener ('mouseenter', this.oE.bind (this));
    this.v.removeEventListener ('mouseleave', this.oL.bind (this));
    this.v.removeEventListener ('click', this.oC.bind (this));
  }
}
