import * as THREE from 'three';

import gsap from 'gsap';

import fragment from '../../shader/about/me/fragment';
import vertex from '../../shader/about/me/vertex';

import disp1 from '../../../images/disp1.jpeg';

import {gb} from '../../utils/gb';

export default class About {
  constructor({scene, loader, geometry, pass}) {
    this.scene = scene;
    this.loader = loader;
    this.geometry = geometry;
    this.pass = pass;
    this.element = document.querySelector ('.content');
    this.images = [...this.element.querySelectorAll ('.intro-img')];
    this.textSpans = [...this.element.querySelectorAll ('.about-bio p span')];
    this.scrollEl = this.element.querySelector ('.about_wrp');
    this.headerHeader = document.querySelector ('.header');
    this.displacement = this.loader.load (disp1);
    this.target = 0;
    this.picTarget = 0;
    this.current = 0;
    this.picCurrent = 0;
    this.me = this.element.querySelector ('.me-container img');
    this.header = document.querySelector ('.margin-line');
    this.date = document.querySelector ('.date-line');
    this.gl = document.querySelector ('#gl');
    this.picWheel = true;
    this.on = false;
    this.bioP = document.querySelector ('.about-bio');

    this.out = true;
    this.s = 0;
    this.pass.material.uniforms.speed.value = 0;
    this.index = 0;

    this.destroyMeshes ();
    this.createMe ();
    this.createBounds ();
    this.onResize ();
  }

  destroyMeshes () {
    // const meshes = this.scene.children;

    // meshes.forEach (mesh => {
    //   if (mesh.name !== 'loader') {
    //     this.scene.remove (mesh);
    //   }
    // });

    this.group = new THREE.Group ();
    this.scene.add (this.group);
  }

  introH () {
    gsap.to ('.about-name-intro span', {
      y: 0,
      duration: 0,
    });
    gsap.to ('.about', {
      autoAlpha: 1,
      duration: 0.2,
    });
    gsap.fromTo (
      '.about-name-intro p',
      {y: '125%'},
      {
        y: 0,
        duration: 0,

        onComplete: () => {
          // document.body.style.pointerEvents = 'all';
        },
      }
    );

    gsap.to (this.material.uniforms.a, {
      value: 0.85,
      delay: 0.11,
    });

    this.introFinished = true;
    this.finished = true;
    this.out = false;
  }

  intro (f) {
    this.out = true;

    this.to = setTimeout (() => {
      // document.body.style.pointerEvents = 'all';
    }, 1200);

    setTimeout (() => {
      this.finished = true;
      // this.gl.style.zIndex = 2;
      let t = 0;
      f ? (t = 1350) : (t = 1000);

      setTimeout (() => {
        this.out = false;
      }, t);
      gsap.to ('.about', {
        autoAlpha: 1,
      });

      if (f) {
        gsap.to ('.about-name-intro span', {
          y: 0,
          delay: 1,
          stagger: 0.1,
          duration: 1.3,
          ease: 'Expo.easeOut',
          onComplete: () => {
            // document.body.style.pointerEvents = 'all';
          },
        });

        gsap.to (this.material.uniforms.a, {
          value: 0.85,
          delay: 1.5,
        });
      } else {
        gsap.to ('.about-name-intro span', {
          y: 0,
          duration: 1.5,
          stagger: 0.13,
          ease: 'Expo.easeOut',
          onComplete: () => {
            // document.body.style.pointerEvents = 'all';
          },
        });

        gsap.fromTo (
          '.about-name-intro p',
          {y: '125%'},
          {
            y: 0,
            duration: 1.2,
            ease: 'Expo.easeOut',
            onComplete: () => {
              // document.body.style.pointerEvents = 'all';
            },
          }
        );

        gsap.to (this.material.uniforms.a, {
          value: 0.85,
          delay: 1,
        });
      }

      this.introFinished = true;
    }, 200);
  }

  hideBc () {
    this.stop = true;
    // gsap.to (this.material.uniforms.a, {
    //   value: 0,
    //   duration: 0.85,
    //   onComplete: () => (this.material.visible = false),
    // });

    gsap.to ('.about-name-intro', {
      y: '-5vh',
      scale: 0.95,
      duration: 1.2,
      ease: 'Power3.easeInOut',
    });

    gsap.to (this.mesh.position, {
      y: this.mesh.position.y + window.innerHeight * 0.2,
      duration: 1.1,
      ease: 'Power3.easeInOut',
    });
    gsap.to (this.mesh.scale, {
      y: this.bounds.height * 0.95,
      x: this.bounds.width * 0.95,
      duration: 1.1,
      ease: 'Power3.easeInOut',
    });
    gsap.to (this.material.uniforms.a, {
      value: 0,
      duration: 1,
      ease: 'Power3.easeInOut',
      onComplete: () => (this.material.visible = false),
    });
  }

  createMe () {
    const d = this.me.getAttribute ('data-src');

    const obj = window.T[d];
    const t = obj[0];
    t.minFilter = THREE.LinearFilter;
    t.magFilter = THREE.LinearFilter;

    this.material = new THREE.ShaderMaterial ({
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: {
        tex: {value: obj[0]},
        disp: {value: this.displacement},
        time: {value: 0},
        a: {value: 0},
      },
      transparent: true,
      side: THREE.DoubleSide,
    });

    this.mesh = new THREE.Mesh (this.geometry, this.material);

    this.group.add (this.mesh);
    this.mesh.rotation.z = 0.25;
    this.mesh.rotation.x = -0.05;
  }

  createBounds () {
    this.bounds = this.me.getBoundingClientRect ();

    this.updateScale ();
  }

  updateScale () {
    this.mesh.scale.set (this.bounds.width, this.bounds.height, 1);

    this.updateX ();
    this.updateY ();
  }

  updateX () {
    this.mesh.position.x =
      this.bounds.left - window.innerWidth / 2 + this.bounds.width / 2;
  }

  updateY (y = 0) {
    this.mesh.position.y =
      y - this.bounds.top + window.innerHeight / 2 - this.bounds.height / 2;
  }

  /**
   * EVENTS
   */

  onWheel (e) {
    if (!this.finished) return;
    if (this.out) return;
    this.picTarget += e.deltaY;

    if (!this.wheel) return;
    this.target += e.deltaY;
  }

  playPr () {
    gsap.to ('.bio-p span', {
      y: 0,
      duration: 1.2,
      stagger: 0.08,
      ease: 'Power3.easeOut',
    });
  }

  onResize () {
    this.v = {
      w: window.innerWidth,
      h: window.innerHeight,
    };

    const {h} = this.v;
    this.limit = this.scrollEl.getBoundingClientRect ().height - h;

    this.bioT = gb (this.bioP).height + this.s;

    this.picLimit = h / 2 + this.mesh.scale.y * 3;

    this.createBounds ();
    this.target = 0;
  }

  animateIn () {
    if (this.out) return;
    const {h} = this.v;
    if (this.mesh.position.y > -h / 2 - this.bounds.height / 2) {
      this.scale = this.mesh.position.y / (-h / 2 - this.bounds.height / 2);

      // this.material.uniforms.fc.value = this.scale;
      this.mesh.rotation.y = this.scale * 0.7;
      this.mesh.rotation.z = this.scale * 0.2;
      this.mesh.rotation.x = this.scale * 0.2;
    }

    if (this.mesh.position.y > h / 2 - this.bounds.height / 2) {
      this.wheel = true;
    }

    if (this.wheel === true && this.mesh.position.y < 0) {
      this.wheel = false;
    }

    if (this.mesh.position.y - this.bounds.height / 3 > h / 1.5) {
      gsap.to (this.pass.material.uniforms.speed, {
        value: 0,
        duration: 0,
        ease: 'Power2.easein',
      });
    } else if (
      this.mesh.position.y - this.bounds.height / 1.5 < h / 2 &&
      this.introFinished &&
      !this.out
    ) {
      if (this.out) return;
      gsap.to (this.pass.material.uniforms.speed, {
        value: 0.5,
        duration: 0,
        ease: 'Power2.easeOut',
      });
    }
  }

  oncEnter (index) {
    if (this.side === -1) {
      let prev = index - 1;
      gsap.to (this.store[index].mesh.material.uniforms.p, {
        value: 1,
        duration: 1,
        ease: 'Power3.easeOut',
      });

      if (prev < 0) return;

      gsap.to (this.store[prev].mesh.material.uniforms.p2, {
        value: 0,
        duration: 1,
        ease: 'Power3.easeOut',
      });
    } else if (this.side === 1) {
      let prev = index + 1;

      if (prev > 3) return;

      gsap.to (this.store[index].mesh.material.uniforms.p2, {
        value: 1,
        duration: 1,
        ease: 'Power3.easeOut',
      });

      gsap.to (this.store[prev].mesh.material.uniforms.p, {
        value: 0,
        duration: 1,
        ease: 'Power3.easeOut',
      });
    }
  }

  update (time) {
    if (this.stop) return;
    if (!this.out) {
      this.animateIn ();
    }

    const {h} = this.v;

    this.s = this.current;

    this.material.uniforms.time.value = time;
    this.target = gsap.utils.clamp (0, this.limit, this.target);
    this.current = gsap.utils.interpolate (this.current, this.target, 0.06);

    this.picCurrent = gsap.utils.interpolate (
      this.picCurrent,
      this.picTarget,
      0.05
    );

    if (this.target < 0.5) {
      this.target = 0;
    }

    if (!this.playedPr) {
      if ((this.bioT - this.s) / h < 0) {
        this.playedPr = true;
        this.playPr ();
      }
    }

    this.picTarget = gsap.utils.clamp (0, this.picLimit, this.picTarget);

    this.updateY (this.picCurrent);

    this.scrollEl.style.transform = `translateY(${-1 * this.current}px)`;
  }

  hDestroy () {
    this.scene.remove (this.group);
    this.pass.material.uniforms.speed.value = 0;
  }

  destroy () {
    setTimeout (() => {
      this.out = true;
      this.scene.remove (this.group);
      this.pass.material.uniforms.speed.value = 0;
    }, 1200);
  }
}
