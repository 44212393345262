import * as THREE from 'three';

import gsap from 'gsap';

import Animation from '../../classes/Animation';

export default class Media extends Animation {
  constructor({el, scene, geometry, index}) {
    super ({el});
    this.el = el;
    this.tr = this.el.parentElement;
    this.index = index;

    this.side = 1;
    this.e = {
      i: this.index === 0 ? 1 : 0,
    };
  }

  intro (f) {
    let d = f ? 0.5 : 0.0;

    if (this.index === 0) this.el.play ();

    gsap.to (this.e, {
      i: 0,
      delay: d + 0.1 * this.index,
      duration: f ? 1.95 : 1.65,
      ease: 'Expo.easeOut',
    });

    setTimeout (() => {
      this.ld ();
    }, f ? 1500 : 1000);
  }

  ld () {
    const src = this.el.getAttribute ('data-src');
    this.el.src = src;
    this.el.classList.remove ('nt-plyd');
    this.el.play ();
    gsap.to ('.v-mk', {
      autoAlpha: 0,
      duration: 1,
      ease: 'Power3.easeOut',
    });
  }

  animateIn () {
    if (this.el.classList.contains ('nt-plyd')) return;
    this.el.play ();
  }
  animateOut () {
    if (this.el.classList.contains ('nt-plyd')) return;
    this.el.pause ();
  }

  hideBc () {}

  cb () {}

  rs () {
    this.cb ();
  }

  i (x) {
    return x < 0.5 ? 2 * x * x : -1 + (4 - 2 * x) * x;
  }

  update (s) {
    if (this.stop) return;
    let i = window.innerHeight * this.i (this.e.i) * this.side;
    this.tr.style.transform = `translateY(${-s + i}px)`;
  }
}
