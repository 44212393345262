import gsap from 'gsap';
import 'regenerator-runtime/runtime';

export const hide = el => {
  return new Promise (resolve => {
    gsap.to (el, {
      autoAlpha: 0,
      duration: 0.2,
      onComplete: resolve,
    });
  });
};

export const hideBc = el => {
  gsap.to (el, {
    y: '-17vh',
    autoAlpha: 0,
    // scale: 0.95,
    duration: 1.2,
    ease: 'Power3.easeInOut',
  });
  // gsap.to (el, {
  //   autoAlpha: 0,
  //   duration: 0.55,
  //   ease: 'Power3.easeOut',
  // });
  return new Promise (resolve => {
    gsap.to (el, {
      x: 0,
      duration: 0.6,
      onComplete: resolve,
    });
  });
};
