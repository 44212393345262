import * as THREE from 'three';

import gsap from 'gsap';

import Media from './Media';

import disp1 from '../../../images/disp1.jpeg';

import {gb} from '../../utils/gb';

export default class Home {
  constructor({scene, loader, geometry, pass, curtain, i}) {
    this.scene = scene;
    this.loader = loader;
    this.geometry = geometry;
    this.pass = pass;
    this.i = parseInt (i);

    this.element = document.querySelector ('.content');
    this.headerHeader = document.querySelector ('.header');
    this.home = this.element.querySelector ('.home');
    this.heroText = [...this.element.querySelectorAll ('.home-hero-text')];
    this.index = 0;
    this.position = 0;
    this.target = 0;
    this.curtain = curtain;
    this.on = false;
    this.wrapper = this.element.querySelector ('.gallery-container');
    this.images = [
      ...this.element.querySelectorAll ('.home-gallery-media-img'),
    ];
    this.count = 0;
    this.count = +1;

    this.titles = [...document.querySelectorAll ('.home-hero-text h3 span')];
    this.paraRev = [
      ...this.titles[this.i].parentElement.parentElement.querySelectorAll (
        '.home-hero-text p span'
      ),
    ];
    this.heroText = [...document.querySelectorAll ('.home-hero-text')];
    this.totalContainer = this.element.querySelector ('.nbr-ref');
    this.sideContainer = this.element.querySelector ('.side-dir');
    this.currentRef = this.element.querySelector ('.current');
    this.header = document.querySelector ('.margin-line');
    this.displacement = this.loader.load (disp1);
    this.gl = document.querySelector ('#gl');

    this.scroll = {
      current: 0,
      target: 0,
      ease: 0.1,
    };
    this.play = false;

    this.destroyMeshes ();
    this.createMedias ();

    this.addEvents ();
    this.onResize ();
  }

  destroyMeshes () {
    // const meshes = this.scene.children;

    // meshes.forEach (mesh => {
    //   if (mesh.name !== 'loader') {
    //     this.scene.remove (mesh);
    //   }
    // });

    this.group = new THREE.Group ();
    this.scene.add (this.group);
  }

  introH () {
    this.intro (false);
  }

  intro (f) {
    this.to = setTimeout (() => {
      // document.body.style.pointerEvents = 'all';
      this.introEnd = true;
      this.play = true;
    }, 1200);

    if (f) {
      gsap.to ('.home-txt-wrap', {opacity: 1, duration: 0});
      gsap.to ('header', {
        opacity: 1,
        duration: 1.5,
        delay: 1.5,
        onComplete: () => (this.introEnd = true),
        ease: 'Power2.easeOut',
      });
      this.medias.forEach (el => el.intro (f));

      gsap.to ('.count', {
        opacity: 1,
        delay: 1,
      });

      gsap.to (['.br-t-c', '.br-b-c'], {
        y: '100%',
        delay: 1.15,
        duration: 0.8,
        ease: 'Power2.easeOut',
      });

      gsap.to ('.count span', {
        y: 0,
        delay: 1.15,
        duration: 0.8,
        ease: 'Power2.easeOut',
      });

      gsap.to ('.h-hy', {
        scaleY: 1,
        delay: 1.15,
        duration: 0.7,
        ease: 'Power1.easeInOut',
      });

      gsap.fromTo (
        this.titles[this.i],
        {y: '100%'},
        {
          y: 0,
          delay: 1,
          duration: 1,
          ease: 'Power3.easeOut',
        }
      );
      gsap.fromTo (
        this.paraRev,
        {y: '100%'},
        {
          y: 0,
          delay: 1.18,
          stagger: 0.06,
          duration: 0.85,
          ease: 'Power2.easeOut',
        }
      );
      // this.titles.forEach ((l, index) => {
      //   index != 0 ? (l.style.transform = 'translateY(0)') : '';
      // });
      // this.heroText.forEach ((el, index) => {
      //   if (index === 0) return;
      //   const text = el.querySelectorAll ('p span');
      //   text.forEach (item => (item.style.transform = 'translateY(0)'));
      // });
    } else {
      setTimeout (() => {
        this.pass.material.uniforms.speed.value = 0;
      }, 200);
      gsap.to ('.home-txt-wrap', {opacity: 1, delay: 0.1, duration: 0});
      gsap.to ('header', {
        opacity: 1,
        duration: 1.5,
        delay: 0.25,
        onComplete: () => (this.introEnd = true),
        ease: 'Power2.easeOut',
      });
      this.medias.forEach (el => el.intro (f));

      gsap.to ('.count', {
        opacity: 1,
        delay: 0.25,
      });

      gsap.to (['.br-t-c', '.br-b-c'], {
        y: '100%',
        delay: 0.5,
        duration: 0.6,
        ease: 'Power2.easeOut',
      });

      gsap.to ('.count span', {
        y: 0,
        delay: 0.5,
        duration: 0.6,
        ease: 'Power2.easeOut',
      });

      gsap.to ('.h-hy', {
        scaleY: 1,
        delay: 0.5,
        duration: 0.5,
        ease: 'Power1.easeInOut',
      });

      gsap.fromTo (
        '.home-txt-wrap',
        {y: '100%'},
        {
          y: 0,
          duration: 1.3,
          ease: 'Expo.easeOut',
        }
      );

      gsap.fromTo (
        this.titles[this.i],
        {y: '100%'},
        {
          y: 0,
          delay: 0.25,
          duration: 0.75,
          ease: 'Power3.easeOut',
        }
      );
      gsap.fromTo (
        this.paraRev,
        {y: '100%'},
        {
          y: 0,
          delay: 0.38,
          stagger: 0.06,
          duration: 0.85,
          ease: 'Power4.easeOut',
        }
      );
      // this.titles.forEach ((l, index) => {
      //   index != 0 ? (l.style.transform = 'translateY(0)') : '';
      // });
      // this.heroText.forEach ((el, index) => {
      //   if (index === 0) return;
      //   const text = el.querySelectorAll ('p span');
      //   text.forEach (item => (item.style.transform = 'translateY(0)'));
      // });
    }
  }

  hide () {
    this.medias.forEach (el => el.hide ());
  }

  hideBc () {
    this.stop = true;
    this.medias.forEach (el => el.hideBc ());
  }

  /**
   * CREATE
   */

  createMedias () {
    this.container = document.querySelector ('.gallery-container');
    this.text = [...document.querySelectorAll ('.home-hero-text')];
    this.length = this.images.length - 1;
    this.curr = document.querySelector ('.h-curr span');
    document.querySelector ('.h-total span').innerHTML = this.length + 1;
    this.medias = this.images.map ((image, index) => {
      return new Media ({
        image,
        geometry: this.geometry,
        scene: this.group,
        loader: this.loader,
        index,
        wrapper: this.wrapper,
        displacement: this.displacement,
        text: this.text[index],
        i: this.i,
        length: this.length,
        curr: this.curr,
      });
    });
  }

  /**
   * EVENts
   */

  onWheel (e) {
    if (!this.introEnd) return;
    if (this.isAnimating) return;

    if (!this.csOpen) {
      this.scroll.target += e.deltaY * 1.2;
    }

    if (this.csOpen) {
      this.cs.onWheel (e);
    }
  }

  onTouchDown (e) {
    this.isDown = true;
    this.touchY = e.touches[0].clientY;
  }

  onTouchMove (e) {
    if (!this.isDown || this.isAnimating) return;
    e.preventDefault ();

    this.distance = this.touchY - e.touches[0].clientY;
    this.target = this.distance;

    if (this.target > 10) {
      this.target = 0;
      this.up ();
    }
  }

  onResize () {
    this.b = gb (this.wrapper);
    const {height} = this.b;
    const ih = gb (this.images[0]);
    const {height: h} = ih;

    if (!this.dn) {
      if (this.i === this.length) {
        this.scroll.current = this.i * 5 + h * this.i - 0.05;
        this.scroll.target = this.i * 5 + h * this.i;
      } else {
        this.scroll.current = this.scroll.target = this.i * 5 + h * this.i;
      }
    } else {
      this.scroll.current = 0;
      this.scroll.target = -0.05;
    }

    this.dn = true;

    setTimeout (() => {
      this.medias.forEach (media =>
        media.onResize (height + 5, this.scroll.current)
      );
    }, 15);
  }

  /**
 * LOOP
 */

  update (time) {
    // if (!this.play) return;

    if (this.stop) return;
    this.scroll.current = gsap.utils.interpolate (
      this.scroll.current,
      this.scroll.target,
      this.scroll.ease
    );

    if (Math.sign (this.scroll.target - this.scroll.current) === 1) {
      this.side = 'u';
    } else {
      this.side = 'd';
    }
    this.medias.forEach (media => {
      media.update (this.scroll.current, time, this.side);
    });
  }

  addEvents () {}

  removeEvents () {}

  hDestroy () {
    this.scene.remove (this.group);
    this.removeEvents ();
  }

  destroy () {
    this.medias.forEach (el => el.destroy ());
    setTimeout (() => {
      this.scene.remove (this.group);
      this.removeEvents ();
    }, 1200);
  }
}
