export const ft = async u => {
  const d = await fetch (u);
  const j = await d.json ();
  return j;
};

export const findR = (d, u) => {
  return Object.keys (d.pages).find (r => r === u);
};

export const findPrR = (d, u) => {
  const id = u.replace ('/project/', '/');

  return Object.keys (d.pages.projects).find (route => id === route);
};
