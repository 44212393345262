import * as THREE from 'three';

import gsap from 'gsap';

import fragment from '../shader/Loader/fragment';
import vertex from '../shader/Loader/vertex';

export default class Loader {
  constructor({geometry, scene, manager, loader}) {
    this.geometry = geometry;
    this.scene = scene;
    this.manager = manager;
    this.l = loader;
    window.T = [];
    this.rgbs = [
      {
        r: '0.06666667',
        g: '0.06666667',
        b: '0.06666667',
      },
      {
        r: '1',
        g: '0.98823529',
        b: '0.96470588',
      },
      {
        r: '0.94509804',
        g: '0.91764706',
        b: '0.89411765',
      },
      {
        r: '0.10196078',
        g: '0.08627451',
        b: '0.09019608',
      },
      {
        r: '0.09019608',
        g: '0.09411765',
        b: '0.10196078',
      },
    ];

    this.createMesh ();
  }

  createMesh () {
    this.material = new THREE.ShaderMaterial ({
      uniforms: {
        time: {value: 0},
        resolution: {value: new THREE.Vector4 ()},
        p: {value: 1},
        c: {value: 1},
        p2: {value: 0},
        r: {value: 0.101},
        g: {value: 0.101},
        b: {value: 0.105},
      },
      vertexShader: vertex,
      fragmentShader: fragment,
    });

    this.mesh = new THREE.Mesh (this.geometry, this.material);
    this.mesh.name = 'loader';

    this.scene.add (this.mesh);
  }

  onResize () {
    this.mesh.scale.set (
      window.innerWidth * 0.835,
      window.innerHeight * 0.835,
      1
    );
  }

  async onLoad (d) {
    let length = 1;

    return new Promise (resolve => {
      d.medias.forEach (m => {
        const img = new Image ();
        img.src = m;
        img.crossOrigin = 'anonymous';
        img.onload = _ => {
          const t = this.l.load (img.src);
          window.T[m] = [t, img.naturalWidth, img.naturalHeight, img];
          length += 1;
          if (length === d.medias.length) {
            setTimeout (() => {
              resolve ('loaded');
            }, 250);
          }
        };
      });
    });
  }

  animateIn () {
    return new Promise (resolve => {
      gsap.fromTo (
        this.material.uniforms.p2,
        {value: 0},
        {
          value: 1,
          duration: 1.5,
          delay: 0.2,
          ease: 'Power2.easeInOut',
          onComplete: () => {
            this.reset ();
            resolve;
            this.material.visible = false;
            this.scene.remove (this.mesh);
          },
        }
      );
    });
  }

  caseOut () {
    gsap.fromTo (
      this.material.uniforms.p2,
      {value: 0},
      {
        value: 1,
        duration: 1.5,
        delay: 0.2,
        ease: 'Power2.easeInOut',
        onComplete: () => {
          this.resetColor ();
        },
      }
    );
  }

  resetColor () {
    this.material.uniforms.r.value = 0.101;
    this.material.uniforms.g.value = 0.101;
    this.material.uniforms.b.value = 0.105;

    gsap.fromTo (
      this.material.uniforms.p2,
      {value: 1},
      {
        value: 0,
        duration: 0,
        ease: 'Power2.easeInOut',
      }
    );
    gsap.fromTo (
      this.material.uniforms.p,
      {value: 1},
      {
        value: 0,
        duration: 0,
        ease: 'Power2.easeInOut',
      }
    );
  }

  animateOut () {
    return new Promise (resolve => {
      this.material.uniforms.r.value = 0.101;
      this.material.uniforms.g.value = 0.101;
      this.material.uniforms.b.value = 0.105;
      gsap.fromTo (
        this.material.uniforms.p,
        {value: 0},
        {
          value: 1,
          duration: 1.5,
          ease: 'Power2.easeInOut',
          onComplete: resolve,
        }
      );
    });
  }

  caseIn (index) {
    this.material.uniforms.r.value = this.rgbs[index].r;
    this.material.uniforms.g.value = this.rgbs[index].g;
    this.material.uniforms.b.value = this.rgbs[index].b;
    gsap.fromTo (
      this.material.uniforms.p,
      {value: 0},
      {
        value: 1,
        duration: 1.5,
        ease: 'Power2.easeInOut',
      }
    );
  }

  reset () {
    this.material.uniforms.r.value = 0.101;
    this.material.uniforms.g.value = 0.101;
    this.material.uniforms.b.value = 0.105;
    gsap.fromTo (
      this.material.uniforms.p2,
      {value: 1},
      {
        value: 0,
        duration: 0,
        ease: 'Power2.easeInOut',
      }
    );
    gsap.fromTo (
      this.material.uniforms.p,
      {value: 1},
      {
        value: 0,
        duration: 0,
        ease: 'Power2.easeInOut',
      }
    );
  }
}
