import gsap from 'gsap';

export default class Preloader {
  constructor({element}) {
    this.element = element;
    this.nameSpans = [...this.element.querySelectorAll ('.wrapper')];
    this.folio = [...this.element.querySelectorAll ('.preloader-folio')];
    this.bg = this.element.querySelector ('.preloader-bg');
  }

  animateOut () {
    gsap.to (this.bg, {
      opacity: 0,
    });
    gsap.to ('.header', {
      y: 0,
      duration: 1.8,
      ease: 'Expo.easeOut',
      delay: 0.95,
    });
    gsap.to ('.contact-links li a', {
      y: 0,
      duration: 1.5,
      stagger: 0.03,
      ease: 'Expo.easeOut',
      delay: 1.22,
    });
    gsap.fromTo (
      this.nameSpans,
      {opacity: 1, rotateX: 0, rotateY: 0, x: 0},
      {
        x: '-300%',
        y: '-50%',
        rotateX: '70deg',
        rotateY: '-120deg',
        opacity: 0,
        duration: 0.4,
        stagger: -0.04,
        ease: 'Power2.easeOut',
        delay: 0.3,
        onComplete: () => this.destroy (),
      }
    );
    gsap.to (this.folio, {
      opacity: 0,
      duration: 0.5,
    });
  }
  destroy () {
    this.element.parentElement.removeChild (this.element);
  }
}
